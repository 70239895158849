/**
 * This has the values from cfg product that are required for exports,
 * since no graphQL endpoint exists.
 *
 * @note These IDs should change to product
 * service IDs in the future, and this file probably won't be a thing that is
 * needed.
 *
 * @note SchoolCity technically exists for IOAdmin, but it's value is null which
 * throws a console error.
 **/
const CFG_PRODUCTS = {
    16: { name: 'DnA', app: ['allthedata'] },
    15: { name: 'FastBridge', app: ['fastbridge'] },
    14: { name: 'School City', app: ['scsuite', 'schoolcity'] },
}

Object.freeze(CFG_PRODUCTS)

export default CFG_PRODUCTS
