<template>
    <v-alert
        v-bind="$attrs"
        border="left"
        close-icon="fal fa-times"
        dense
        :icon="false"
        text
        v-on="$listeners"
    >
        <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
    </v-alert>
</template>

<script>
export default {
    name: 'AlertMessage',
}
</script>

<style lang="scss" scoped>
.alert-message {
    align-self: flex-start;
}
</style>