<template>
    <base-dialog
        v-model="open"
        max-width="1360px"
        :title="title"
        :confirm-action="submit"
        :close-on-submit="false"
        @close="back"
    >
        <template #content>
            <v-card-text>
                <v-skeleton-loader v-if="!mountFinished" type="heading, article" class="mt-4" />
                <extended-form
                    v-else
                    ref="form"
                    :form-ready="form.ready"
                    :form-data="form.data"
                    :show-submit="false"
                    :disabled="!canEdit || fetching || saving"
                    @formValid="(valid) => (form.ready = valid)"
                >
                    <template #default>
                        <v-card-text>
                            <alert-message
                                v-if="missingFBInstance"
                                class="mb-4"
                                type="warning"
                                style="margin: auto"
                                rounded
                            >
                                <strong>Missing FastBridge Instance!</strong>
                                Create a FastBridge instance before continuing.&rarr;
                                <router-link :to="{ name: 'instance-create', query: { clientId } }">
                                    Create Instance
                                </router-link>
                                <template v-if="showMissingClientWarning">
                                    <strong>Client could not be found</strong>
                                    This district does not have an associated client &rarr;
                                    <router-link :to="{ name: 'district-profile' }">
                                        Edit District
                                    </router-link>
                                </template>
                            </alert-message>
                            <v-alert
                                v-if="sisTime && selectedDistrict.cfgSisSystemId"
                                color="rgba(254, 247, 224, 1)"
                                border="left"
                                class="banner py-2"
                            >
                                SIS Import is scheduled at
                                {{ sisTime }} (EST).
                            </v-alert>
                            <v-row>
                                <v-col md="6">
                                    <drop-down
                                        v-if="isAdd"
                                        :form="form"
                                        field-key="schoolYear"
                                        label="Year"
                                        :items="years"
                                        hide-details="auto"
                                        :loading="yearsFetching"
                                        required
                                    />
                                    <text-field
                                        v-else
                                        :form="form"
                                        field-key="schoolYear"
                                        disabled
                                        label="Year"
                                        hide-details="auto"
                                    />
                                    <text-field
                                        v-if="hasFormField('ftpServer')"
                                        :form="form"
                                        field-key="ftpServer"
                                        label="SFTP Server"
                                        required
                                    />
                                    <text-field
                                        v-if="hasFormField('ftpPath')"
                                        :form="form"
                                        field-key="ftpPath"
                                        label="SFTP Path"
                                        :readonly="isProductType('fastbridge')"
                                        :disabled="isProductType('fastbridge')"
                                        required
                                    />
                                    <text-field
                                        v-if="hasFormField('ftpPort')"
                                        :form="form"
                                        field-key="ftpPort"
                                        label="SFTP Port"
                                        required
                                    />
                                    <text-field
                                        v-if="hasFormField('runTime')"
                                        :form="form"
                                        field-key="runTime"
                                        label="Run Time (EST)"
                                        type="time"
                                        required
                                    />
                                </v-col>
                                <v-col md="6">
                                    <text-field
                                        v-if="hasFormField('ftpUserName')"
                                        :form="form"
                                        field-key="ftpUserName"
                                        label="SFTP Username"
                                        required
                                    />
                                    <text-field
                                        v-if="hasFormField('ftpPassword')"
                                        :form="form"
                                        field-key="ftpPassword"
                                        label="SFTP Password"
                                        placeholder="Password will be encrypted by the server"
                                        required
                                    />
                                    <text-field
                                        v-if="!isAdd"
                                        :form="form"
                                        field-key="lastRun"
                                        label="Last Run"
                                        disabled
                                        prepend-inner-icon="fal fa-calendar-alt"
                                    >
                                        <template #prepend-inner>
                                            <v-icon size="18px" class="mt-1"
                                                >fal fa-calendar-alt</v-icon
                                            >
                                        </template>
                                    </text-field>
                                    <text-field
                                        v-if="!isAdd"
                                        :form="form"
                                        field-key="nextScheduledRun"
                                        label="Next Export"
                                        disabled
                                    >
                                        <template #prepend-inner>
                                            <v-icon size="18px" class="mt-1"
                                                >fal fa-calendar-alt</v-icon
                                            >
                                        </template>
                                    </text-field>
                                    <v-switch
                                        v-model="form.data.holdExport"
                                        class="mt-1"
                                        label="Hold Export"
                                        inset
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </template>
                </extended-form>
            </v-card-text>
        </template>
        <template #actions>
            <v-btn disabled>Export Now</v-btn>
        </template>
    </base-dialog>
</template>

<script>
import TextField from '@/components/form/TextField'
import ExtendedForm from '@/components/form/ExtendedForm'
import { ROLE_SETUP_DISTRICT_EDIT } from '@/helpers/security/roles'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog'
import { formSnackbar } from '@/helpers/snackbar'
import { DateTime } from 'luxon'
import cfgProducts from '@/helpers/mappings/cfgProduct'
import DropDown from '@/components/form/DropDown'
import { displayDate } from '@/helpers/form/formatting.js'
import setVar from '@/helpers/variable-set'
import AlertMessage from '@/components/alert/AlertMessage'
import formConfig from '@/helpers/form/export-form-config'

export default {
    name: 'Export',
    components: { BaseDialog, TextField, ExtendedForm, DropDown, AlertMessage },
    props: {
        id: {
            type: String,
            default: null,
        },
        districtId: {
            type: String,
            required: true,
        },
        productId: {
            type: String,
            default: null,
        },
    },
    data: function () {
        return {
            open: true,
            form: {
                ready: false,
                errors: [],
                current: null,
                rules: {
                    schoolYear: [(v) => !!v || 'Year is required'],
                    ftpServer: [(v) => !!v || 'A hostname is required'],
                    ftpPath: [(v) => !!v || 'A path is required'],
                    ftpPort: [
                        (v) => !!v || 'A port is required',
                        (v) => parseInt(v) > 0 && parseInt(v) < 65565,
                    ],
                    ftpUserName: [(v) => !!v || 'Username is required'],
                    ftpPassword: [(v) => !!v || 'Password is required'],
                    runTime: [
                        (v) => !!v || 'Run time is required',
                        (v) =>
                            /[0-2]?\d:[0-6]\d/.test(v) ||
                            'Run time must be in a 24h format (e.g. 21:00)',
                    ],
                },
                data: {
                    ftpServer: null,
                    ftpPort: 22,
                    ftpPath: null,
                    ftpUserName: null,
                    ftpPassword: null,
                    runTime: '18:00',
                    lastRun: null,
                    blockExport: false,
                    schoolYear: null,
                    syncNow: false,
                    holdExport: false,
                    exportNow: false,
                    nextScheduledRun: null,
                },
            },
            timeFields: ['runTime'],
            dateFields: ['lastRun', 'nextScheduledRun'],
            mountFinished: false,
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            getItemById: 'export/getItemById',
            exports: 'export/getItems',
            fetching: 'export/getFetching',
            saving: 'export/getSaving',
            selectedDistrict: 'getSelectedDistrict',
            errors: 'export/getErrors',
            getYears: 'schoolYear/getItems',
            yearsFetching: 'schoolYear/getFetching',
            clientId: 'getIlluminateClientId',
            instances: 'instance/getItems',
            fetchingInstances: 'instance/getFetching',
            getItemsByFields: 'instance/getItemsByFields',
        }),
        years() {
            return this.getYears.map((y) => y.schoolYear)
        },
        title: {
            get() {
                const productId = this.productId || this.current?.productId

                const product = cfgProducts[productId]
                return 'Product Export'.concat(product ? ` to ${product.name}` : '')
            },
            cache: false,
        },
        canEdit: function () {
            return this.hasRole(ROLE_SETUP_DISTRICT_EDIT) && !this.missingFBInstance
        },
        isAdd: function () {
            return this.$route.name === 'export-add'
        },
        isEdit: function () {
            return this.$route.name === 'export-edit'
        },
        sisTime: function () {
            if (this.selectedDistrict.sisSyncTime) {
                return DateTime.fromFormat(
                    this.selectedDistrict.sisSyncTime,
                    'HH:mm'
                ).toLocaleString(DateTime.TIME_SIMPLE)
            }
            return ''
        },
        product() {
            const productId = this.productId || this.current?.productId

            return cfgProducts[productId] || { app: [], name: 'Default' }
        },
        missingFBInstance() {
            const instances = this.getItemsByFields({
                clientId: this.clientId,
                application: 'fastbridge',
            })

            return (
                this.isProductType('fastbridge') &&
                instances.length === 0 &&
                !this.fetchingInstances
            )
        },
        showMissingClientWarning() {
            return this.isProductType('fastbridge') && !this.clientId
        },
    },
    watch: {
        open(open) {
            if (!open) {
                this.$router.push({
                    name: 'export-overview',
                    params: { districtId: this.districtId },
                })
            }
        },
    },

    async mounted() {
        if (!this.isEdit) {
            this.fetchYears({ lea: this.selectedDistrict.lea })

            /** If creating a fastbridge export then get the fb instance's ncesId  */
            if (this.product.app.includes('fastbridge') && this.clientId) {
                await this.fetchInstances({ params: { clientId: this.clientId } })
                const instances = this.getItemsByFields({
                    clientId: this.clientId,
                    application: 'fastbridge',
                })

                if (instances.length > 0) {
                    const instanceLea = instances[0]?.deploymentOptions?.ncesId
                    this.form.data.ftpPath = `district_${instanceLea}/upload/student/full`
                }
            }
        }

        if (this.id) {
            // assuming you're coming from the list directly, this should already be loaded into memory.
            if (!this.getItemById(this.id)) {
                await this.fetch({ id: this.id })
            }
            this.current = this.getItemById(this.id)
            this.form.data = Object.keys(this.form.data).reduce((form, field) => {
                if (this.timeFields.includes(field)) {
                    // Parse the time from ISO format and display it in 01:30 PM format
                    form[field] = this.current[field]
                        ? DateTime.fromISO(this.current[field]).setZone('est').toFormat('HH:mm')
                        : this.current[field]

                    return form
                }
                if (this.dateFields.includes(field)) {
                    const dt = DateTime.fromISO(this.current[field])
                    // probably an invalid value.
                    if (dt.year < 2000) {
                        form[field] = ''
                    } else {
                        form[field] = displayDate(this.current[field])
                    }

                    return form
                }

                form[field] = this.current[field] ?? form[field]

                return form
            }, {})
            this.mountFinished = true
            // prefetch related entries for people who can edit. Don't wait for them, we only need on submit.
            if (this.canEdit) {
                this.fetch({
                    lea: this.selectedDistrict.lea,
                    productId: this.current.productId,
                    schoolYear: this.current.schoolYear,
                })
            }
        }
        this.mountFinished = true
    },
    methods: {
        ...mapActions({
            fetch: 'export/get',
            patch: 'export/patch',
            post: 'export/post',
            fetchYears: 'schoolYear/get',
            fetchInstances: 'instance/cget',
        }),

        hasFormField(field) {
            let fields = this.product.app.reduce((acc, curr) => {
                if (formConfig[curr]) {
                    acc = formConfig[curr]
                }

                return acc
            }, formConfig.defaults)

            return fields.includes(field)
        },

        isProductType(type) {
            return this.product?.app.includes(type)
        },

        postExport() {
            const data = { ...this.form.data }

            delete data.lastRun
            data.lea = this.selectedDistrict.lea
            data.productId = parseInt(this.productId)
            data.schoolYear = parseInt(data.schoolYear)
            data.runTime = DateTime.fromFormat(data.runTime, 'HH:mm', {
                zone: 'est',
            })
                .toUTC()
                .toISOTime()

            return this.post({ data, lea: this.selectedDistrict.lea })
        },

        patchExport() {
            // while the page loads, it loads all exports matching the schoolYear and product of this one.
            // we want to apply the changes that were made here to all of those products in addition.
            const info = { ...this.form.data }
            delete info.nextExport

            info.runTime = DateTime.fromFormat(info.runTime, 'HH:mm', {
                zone: 'est',
            })
                .toUTC()
                .toISOTime()

            const data = this.exports.reduce((acc, item) => {
                acc.push({
                    id: item.id,
                    ...info,
                })

                return acc
            }, [])

            return this.patch({
                data,
                lea: this.selectedDistrict.lea,
            })
        },

        async submit() {
            if (!this.$refs.form.validate()) {
                return
            }

            const resp = this.id ? await this.patchExport() : await this.postExport()
            // handle response.
            if (this.errors.length) {
                const errors = this.errors[0] || null
                this.form.errors = errors ? errors.message : []

                return
            }
            // Updates return an object of objects. Inserts return an object of arrays.
            const exportData = this.id
                ? Object.values(resp.data).shift()
                : Object.values(resp.data).shift().shift()

            if (exportData) {
                const product = cfgProducts[exportData.productId]
                const identifier = `${product?.name} - ${exportData?.schoolYear}`

                await formSnackbar({
                    isEdit: this.id !== null,
                    type: 'Export',
                    identifier,
                })
                this.open = false
                setVar('$dirtyForm', false)
                await this.$router.push({
                    name: 'export-overview',
                    params: { districtId: this.districtId },
                })
            }
        },
        back() {
            this.$router.push({
                name: 'export-overview',
                params: { districtId: this.districtId },
            })
        },
    },
}
</script>
<style scoped>
.banner {
    border-radius: 0;
}

.banner >>> .v-alert__content {
    color: rgba(176, 96, 0, 1);
}

.banner >>> .v-alert__border {
    background-color: rgba(176, 96, 0, 1);
    color: rgba(176, 96, 0, 1);
    opacity: 1;
}
</style>
