export default {
    fastbridge: [
        'ftpPath',
        'runTime',
        'lastRun',
        'blockExport',
        'schoolYear',
        'syncNow',
        'holdExport',
        'exportNow',
        'nextScheduledRun',
    ],
    defaults: [
        'ftpServer',
        'ftpPort',
        'ftpPath',
        'ftpUserName',
        'ftpPassword',
        'runTime',
        'lastRun',
        'blockExport',
        'schoolYear',
        'syncNow',
        'holdExport',
        'exportNow',
        'nextScheduledRun',
    ],
}
